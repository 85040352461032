import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { validate } from "uuid";
import {
  Form,
  Col,
  Button,
  Modal,
  Row,
  Image,
  ToggleButton,
  ToggleButtonGroup,
  Alert,
} from "react-bootstrap";
import EBanking from "../../assets/logo_moneygiver_ebanking.png";
import {
  ServiceGetDocumentsApertureAccount,
  ServiceGetDocumentsRejected,
  ServiceUpdateDocument,
} from "../../services/clients/serviceClientsAccounts";
import CustomModal from "../shared/Modal";
import { API_BASE_URL } from "../../helpers/GlobalVariables";
import {
  getDocumentTypeById,
  getDocumentDescriptionById,
  getDocumentTypeByIdAlter,
  getDocumentDescriptionByIdAlter,
} from "../../helpers/GetDocuments.tsx";
const FormularioDinamico = () => {
  const [campos, setCampos] = useState([{}]);
  const [formData, setFormData] = useState({
    documentos: [],
  });
  const location = useLocation();
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [isAoWrong, setIsAoWrong] = useState(false);
  const [isAllDocumentsAccepted, setIsAllDocumentsAccepted] = useState(false);
  const [isAllDocumentsPending, setIsAllDocumentsPending] = useState(false);
  const [isSomeDocumentsCorrected, setIsSomeDocumentsCorrected] =
    useState(false);
  const [errors, setErrors] = useState({});
  const [fileErrors, setFileErrors] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalButtonVariant, setModalButtonVariant] = useState("");
  const [modalButtonText, setModalButtonText] = useState("");
  const [modalMessageResponse, setModalMessageResponse] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  const idPreRegistro = queryParams.get("ao");

  const isDevEnv = () => {
    const currentPath = location.pathname;
    const isDevPath = currentPath.includes("/formulario_subsanar_documentos");
    return isDevPath;
  };

  const fetchDocuments = async () => {
    if (!idPreRegistro) {
      setIsAoWrong(true);
      setShowForm(false);
      return "";
    }
    if (!validate(idPreRegistro.toLocaleLowerCase())) {
      setIsAoWrong(true);
      setShowForm(false);
      return "";
    }

    const isDev = isDevEnv();

    try {
      const documentosRechazados = await ServiceGetDocumentsRejected(
        idPreRegistro,
        isDev
      );

      const AllDocumentsAccepted = documentosRechazados.documentos.every(
        (item) => item.nombreEstatus === "Aprobado"
      );

      const AllDocumentsArePending = documentosRechazados.documentos.every(
        (item) => item.nombreEstatus === "Pendiente"
      );

      const someDocumentsWhereCorrect = documentosRechazados.documentos.find(
        (item) => item.nombreEstatus === "Subsanado"
      );

      if (AllDocumentsAccepted) {
        setShowForm(false);
        setIsAllDocumentsAccepted(true);
        return"Todos los documentos fueron aceptados";
      }

      if (AllDocumentsArePending) {
        setShowForm(false);
        setIsAllDocumentsPending(true);
        return "Todos los documentos están pendientes";
      }

      if (someDocumentsWhereCorrect) {
        setShowForm(false);
        setIsSomeDocumentsCorrected(true);
        return "Algunos documentos fueron corregidos";
      }

      // Filtrar los documentos que tienen idEstatus igual a 3
      const documentosFiltrados = documentosRechazados.documentos.filter(
        (documento) => documento.nombreEstatus === "Rechazado"
      );

      const nuevosArchivos = documentosFiltrados.map((documento) => ({
        idDocumento: documento.idDocumento,
        nombre: getDocumentTypeById(documento.idDocumento),
        tipo: "file",
        required: true,
        description: getDocumentDescriptionById(documento.idDocumento),
        observaciones: documento.observaciones,
      }));

      setCampos(nuevosArchivos);
    } catch (error) {
      console.error("Error fetching documents:", error);
      setIsAoWrong(true);
      setShowForm(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  // Función para mostrar el modal
  const handleShowModal = (
    title,
    body,
    buttonText,
    buttonVariant,
    folioGrupoEmpresarial
  ) => {
    setModalTitle(title);
    setModalBody(body);
    setModalButtonText(buttonText);
    setModalButtonVariant(buttonVariant);
    setModalMessageResponse(folioGrupoEmpresarial);
    setShowModal(true);
  };

  // Función para ocultar el modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Ejemplo de cómo utilizar el modal de éxito
  const handleSuccessModal = (message) => {
    handleShowModal("Formulario Enviado", message, "Cerrar", "primary");
  };

  // Ejemplo de cómo utilizar el modal de error
  const handleErrorModal = (message, type) => {
    handleShowModal("Aviso", message, "Cerrar", type);
  };

  const handleChange = (e) => {
    const { name = "", type, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Manejar validaciones según el nombre del campo
    let newErrors = { ...errors };

    if (name.toUpperCase().includes("TELEFONO")) {
      if (!value.trim()) {
        newErrors[name] = "Este campo es requerido.";
      } else if (!/^[0-9]{10}$/.test(value)) {
        newErrors[name] = "El teléfono debe contener 10 dígitos numéricos.";
      } else {
        delete newErrors[name];
      }
    } else if (name.toUpperCase().includes("RFC")) {
      const rfcRegex = /^[A-Z&Ña-zñ]{3,4}[0-9]{6}[0-9A-Za-z]{3}$/i;

      if (!value.trim()) {
        newErrors[name] = "Este campo es requerido.";
      } else if (!rfcRegex.test(value.toUpperCase())) {
        newErrors[name] = "RFC inválido. Verifica el formato.";
      } else {
        delete newErrors[name];
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
    setErrors(newErrors);
    if (type === "file") {
      const idDocumento = e.target.closest(".my-3").id;
      const file = e.target.files[0];

      const validTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      const maxSize = 15 * 1024 * 1024; // 15 MB

      if (file) {
        if (!validTypes.includes(file.type)) {
          setFileErrors((prevErrors) => ({
            ...prevErrors,
            [idDocumento]:
              "Tipo de archivo no válido. Solo se permiten JPG, JPEG, PNG, PDF Y EXCEL.",
          }));

          e.target.setCustomValidity("Invalid");
          return;
        }

        if (file.size > maxSize) {
          setFileErrors((prevErrors) => ({
            ...prevErrors,
            [idDocumento]: "El archivo no debe superar los 15 MB.",
          }));

          e.target.setCustomValidity("Invalid");
          return;
        }
        e.target.setCustomValidity("");
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64String = reader.result.split(",")[1];

          const nuevoDocumento = {
            idDocumento: idDocumento,
            nombreDocumento: file.name,
            docBase64: base64String,
            //idEstatus : 14
          };

          setFileErrors((prevErrors) => ({
            ...prevErrors,
            [idDocumento]: "",
          }));

          setFormData((prevData) => ({
            ...prevData,
            documentos: [...prevData.documentos, nuevoDocumento],
          }));
        };
        reader.onerror = (error) => {
          console.error("Error al leer el archivo:", error);
        };
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity()) {
      try {
        const isDev = isDevEnv();
        const request = {
          documentos: formData["documentos"],
        };
        const response = await ServiceUpdateDocument(
          idPreRegistro,
          isDev,
          request
        );

        // Manejo de la respuesta
        if (response?.success || response?.httpCode === 200) {
          setFormData({});
          handleSuccessModal(
            "Documentos actualizados, por favor estar pendiente de su correo electrónico para más retroalimentación"
          );
        } else {
          handleErrorModal(response.message, "success");
        }
      } catch (error) {
        console.error("Error en la actualización de los documento:", error);
        handleErrorModal(error, "danger");
      }
    }
    setValidated(true);
  };

  useEffect(() => {
    fetchDocuments();
  }, [handleSubmit]);

  return (
    <div className="container  p-5 d-flex flex-column align-items-center justify-content-center ">
      <Image src={EBanking} className="img-fluid pb-3" />
      {isAoWrong && (
        <>
          <h3>
            <Alert variant="warning">
              Parace que no se ha proporcionado la información correcta,
              verifique que su folio en el link este correcto
            </Alert>
          </h3>
        </>
      )}
      {isAllDocumentsAccepted && (
        <>
          <h3>
            <Alert variant="success">
              Excelente, todos los documentos han sido aceptados, ahora el
              equipo de MoneyGiver continuará con su proceso de alta en el
              sistema.
            </Alert>
          </h3>
        </>
      )}
      {isAllDocumentsPending && (
        <>
          <h3>
            <Alert variant="success">
              Estamos revisando su solicitud, tendrá noticias pronto, por favor
              estar pendiente de sus correos y/o telefonos
            </Alert>
          </h3>
        </>
      )}

      {isSomeDocumentsCorrected && (
        <>
          <h3>
            <Alert variant="secondary">
              Sus documentos han sido subsanados, por favor, mantengáse
              pendiente de sus vias de contacto (correos y/o telefonos).
            </Alert>
          </h3>
        </>
      )}
      {showForm && (
        <>
          <h3>
            <Alert variant="secondary">
              Por favor proporcione los documentos de manera correcta y como se
              le especificó.
            </Alert>
          </h3>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="row">
              {campos.map((campo, index) => (
                <Col
                  xs={12}
                  md={12}
                  xl={12}
                  className="my-3"
                  id={campo.idDocumento}
                  key={campo.idDocumento}
                >
                  {campo.tipo === "file" ? (
                    <Form.Group controlId={`formFile${campo.idDocumento}`}>
                      <Form.Label className="ralewayRegular">
                        {campo.nombre}{" "}
                        {campo.required ? (
                          <span className="text-danger">*</span>
                        ) : null}
                      </Form.Label>
                      {!campo.description ? null : (
                        <>
                          <br /> <Form.Text>{campo.description}</Form.Text>
                        </>
                      )}
                      <Form.Control
                        type="file"
                        name={campo.idDocumento}
                        onChange={handleChange}
                        required={true}
                      />

                      <Form.Control.Feedback type="invalid">
                        {fileErrors[campo.idDocumento] ||
                          "Este campo es requerido."}
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    <Form.Group controlId={`formFile${campo.idDocumento}`}>
                      <Form.Label className="ralewayRegular">
                        {campo.nombre}

                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type={campo.tipo}
                        name={campo.nombre}
                        onChange={handleChange}
                        required={campo.required ?? false}
                      />
                      <Form.Control.Feedback type="invalid">
                        {fileErrors[campo.idDocumento] ||
                          "Este campo es requerido."}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Col>
              ))}
            </Row>
            <Col md={4}>
              <button type="submit" className="ralewayBold button-mg-blue">
                Enviar
              </button>
            </Col>
          </Form>
        </>
      )}

      {/* Modal reutilizable */}
      <CustomModal
        show={showModal}
        onHide={handleCloseModal}
        title={modalTitle}
        body={modalBody}
        buttonText={modalButtonText}
        buttonVariant={modalButtonVariant}
        responseContent={modalMessageResponse}
      />
    </div>
  );
};

export default FormularioDinamico;
