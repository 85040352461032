import React, { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import axios from 'axios';
const CompanyForm = ({ formData, onFormDataChange }) => {
    const [errors, setErrors] = useState({});
    const [showIdGrupo, setShowIdGrupo] = useState(false);

    const validateRFC = (rfc) => {
        const regex = /^[A-Z]{3}\d{6}[A-Z0-9]{3}$/;
        return regex.test(rfc);
    };

    const [countries, setCountries] = useState([]);

    useEffect(() => {
        axios.get('https://restcountries.com/v3.1/all')
            .then((response) => {
                const countryList = response.data.map((country) => ({
                    name: country.name.common,
                    code: country.cca2
                }));
                // Ordenar los países alfabéticamente
                countryList.sort((a, b) => a.name.localeCompare(b.name));
                setCountries(countryList);
            })
            .catch((error) => {
                console.error("Error fetching country data: ", error);
            });
    }, []);

    useEffect(() => {
        validateForm(); // Valida el formulario al cargar por primera vez
    }, []);

    useEffect(() => {
        validateForm(); // Valida cada vez que el formulario cambia
    }, [formData, showIdGrupo]);

    const validateForm = () => {
        const newErrors = {};
        let valid = true;

        if (!formData.companyName.trim()) {
            newErrors.companyName = 'El nombre de la empresa es requerido';
            valid = false;
        }
        if (!formData.socialReason.trim()) {
            newErrors.socialReason = 'La razón social es requerida';
            valid = false;
        }

        if (!formData.merchantRFC.trim()) {
            newErrors.merchantRFC = 'El RFC es requerido';
            valid = false;
        } else if (!validateRFC(formData.merchantRFC)) {
            newErrors.merchantRFC = 'RFC inválido, por favor verifique.';
            valid = false;
        }

        if (!formData.country.trim()) {
            newErrors.country = 'El país es requerido';
            valid = false;
        }
        if (!formData.city.trim()) {
            newErrors.city = 'La ciudad es requerida';
            valid = false;
        }
        if (!formData.street.trim()) {
            newErrors.street = 'El nombre de la calle es requerido';
            valid = false;
        }
        if (!formData.postalCode.trim()) {
            newErrors.postalCode = 'El código postal es requerido';
            valid = false;
        }
        if (!formData.state.trim()) {
            newErrors.state = 'El estado es requerido';
            valid = false;
        }
        if (!formData.exteriorNumber.trim()) {
            newErrors.exteriorNumber = 'El número exterior es requerido';
            valid = false;
        }
        if (!formData.colony.trim()) {
            newErrors.colony = 'La colonia es requerida';
            valid = false;
        }

        if (formData.years <= 0) {
            newErrors.years = 'El año debe ser al menos de un año';
            valid = false;
        }

        // Validación del folio empresarial dependiendo del grupo empresarial
        if (!formData.companyId.trim() && !showIdGrupo) {
            newErrors.companyId = 'El folio del grupo empresarial es requerido';
            valid = false;
        }

        setErrors(newErrors);
        onFormDataChange(formData, valid);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        onFormDataChange({
            ...formData,
            [name]: name === "merchantRFC" ? value.toUpperCase() : value
        }, false);
    };
    const handleShowIdGrupo = (e) => {
        setShowIdGrupo(e.target.value === "true");
        formData.companyGroup = e.target.value === "true";
        validateForm();
    };

    return (
        <Form noValidate>
            <Row>
                <Col className='my-3' md={6}>
                    <Form.Group controlId="companyName">
                        <Form.Label className="ralewayRegular">Nombre de la empresa <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            isInvalid={!!errors.companyName}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.companyName}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className='my-3' md={6}>
                    <Form.Group controlId="socialReason">
                        <Form.Label className="ralewayRegular">Razón social <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="socialReason"
                            value={formData.socialReason}
                            onChange={handleChange}
                            isInvalid={!!errors.socialReason}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.socialReason}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className='my-3' md={6}>
                    <Form.Group controlId="merchantRFC">
                        <Form.Label className="ralewayRegular">RFC <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="merchantRFC"
                            value={formData.merchantRFC}
                            onChange={handleChange}
                            isInvalid={!!errors.merchantRFC}
                            maxLength={12} // Limita el input a 12 caracteres
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.merchantRFC}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className='my-3' md={6}>
                    <Form.Group controlId="descripcion">
                        <Form.Label className="ralewayRegular">Comentarios</Form.Label>
                        <Form.Control
                            type="text"
                            name="descripcion"
                            value={formData.descripcion}
                            onChange={handleChange}
                            isInvalid={!!errors.descripcion}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.descripcion}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className='my-3' md={6}>
                    <Form.Group controlId="country">
                        <Form.Label className="ralewayRegular">País <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            as="select"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            isInvalid={!!errors.country}
                        >
                            <option value="">Selecciona un país</option>
                            {countries.map((country) => (
                                <option key={country.code} value={country.name}>
                                    {country.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.country}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className='my-3' md={6}>
                    <Form.Group controlId="state">
                        <Form.Label className="ralewayRegular">Estado <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                            isInvalid={!!errors.state}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.state}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>


            </Row>
            <Row>
                <Col className='my-3' md={6}>
                    <Form.Group controlId="city">
                        <Form.Label className="ralewayRegular">Ciudad <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            isInvalid={!!errors.city}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.city}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className='my-3' md={6}>
                    <Form.Group controlId="street">
                        <Form.Label className="ralewayRegular">Calle <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="street"
                            value={formData.street}
                            onChange={handleChange}
                            isInvalid={!!errors.street}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.street}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className='my-3' md={6}>
                    <Form.Group controlId="postalCode">
                        <Form.Label className="ralewayRegular">Código Postal <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="postalCode"
                            value={formData.postalCode}
                            onChange={handleChange}
                            isInvalid={!!errors.postalCode}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.postalCode}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className='my-3' xs={6} md={6} xl={6}>
                    <Form.Group controlId="colony">
                        <Form.Label className="ralewayRegular">Colonia <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="colony"
                            value={formData.colony}
                            onChange={handleChange}
                            isInvalid={!!errors.colony}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.colony}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

            </Row>
            <Row>

                <Col className='my-3' xs={6} md={6} xl={6}>
                    <Form.Group controlId="exteriorNumber">
                        <Form.Label className="ralewayRegular">Número exterior <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="exteriorNumber"
                            value={formData.exteriorNumber}
                            onChange={handleChange}
                            isInvalid={!!errors.exteriorNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.exteriorNumber}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className='my-3' xs={6} md={6} xl={6}>
                    <Form.Group controlId="interiorNumber">
                        <Form.Label className="ralewayRegular">Número interior</Form.Label>
                        <Form.Control
                            type="text"
                            name="interiorNumber"
                            value={formData.interiorNumber}
                            onChange={handleChange}
                            isInvalid={!!errors.interiorNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.interiorNumber}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>


                <Col className='my-3' xs={12} md={12} xl={12}>
                    <Form.Group controlId="years">
                        <Form.Label className="ralewayRegular">Años de residencia <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="years"
                            value={formData.years}
                            onChange={handleChange}
                            isInvalid={!!errors.years}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.years}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>

                <Col xs={12} md={12} xl={12} className="my-3">
                    <Form.Label className="ralewayRegular px-2">
                        ¿Usted requiere crear un grupo empresarial? (Si no tiene un
                        folio por favor marque Si)
                    </Form.Label>
                    <Form.Check
                        inline
                        type="radio"
                        label="Sí"
                        name="grupoEmpresarial"
                        value="true"
                        checked={showIdGrupo === true}
                        onChange={handleShowIdGrupo}
                    />
                    <Form.Check
                        inline
                        type="radio"
                        label="No"
                        name="grupoEmpresarial"
                        value="false"
                        checked={showIdGrupo === false}
                        onChange={handleShowIdGrupo}
                    />

                    <Form.Group controlId="companyId">
                        <Form.Label className="ralewayRegular">
                            Coloque su clave empresarial si tiene un codigo de grupo.{" "}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            name="companyId"
                            value={formData.companyId}
                            onChange={handleChange}
                            disabled={showIdGrupo}
                            isInvalid={!!errors.companyId}
                            type="text"
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.companyId}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

        </Form>
    );
};

export default CompanyForm;
