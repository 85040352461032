import React, { useState, useEffect } from 'react';
import { Button, Alert, Spinner } from 'react-bootstrap';
import { Stepper, Step } from 'react-form-stepper';
import CompanyForm from './formulariosPartesPreRegistro/formularioEmpresa';
import LegalRepresentativeForm from './formulariosPartesPreRegistro/formularioRepresentante';
import ContactDetailsForm from './formulariosPartesPreRegistro/formularioDatoContacto';
import DocumentationFilesForm from './formulariosPartesPreRegistro/formularioDocumentacion';
import { ServiceGetDocumentsApertureAccount } from '../../../services/clients/serviceClientsAccounts';
import ResumenFormulario from './resumenFormulario';
import { ServicePostMerchantAdd } from '../../../services/merchants/serviceMerchant';
import { ApiPostApertureClient } from '../../../services/clients/ClientsAccounts';
import { AlertaFormulario } from './formulariosPartesPreRegistro/alertaFormulario';
import { translatePreRegisterToSpanish } from '../../../services/clients/TranslationsClients';
import { AperturaCuentaRequest } from './formTemplates/requestAperturaCuenta';
const FormularioPreRegistro = () => {
	const [step, setStep] = useState(0);
	const [loading, setLoading] = useState(false);
	const [ocurredError, setOcurredError] = useState(false);
	const steps = ['Empresa', 'Representante', 'Datos de contacto', 'Documentación', 'Finalizado'];
	const [isAllFormsComplete, setIsAllFormsComplete] = useState(false);
	const [responseNewPreRegister, setResponseNewPreRegister] = useState({
		type: "",
		message: "",
		httpStatusCode: 0,
		idPreRegister: "",
		idGroup: ""

	})

	const [companyData, setCompanyData] = useState({
		companyName: '',
		socialReason: '',
		merchantRFC: '',
		descripcion: '',
		country: "Mexico",
		city: '',
		street: '',
		postalCode: '',
		state: '',
		exteriorNumber: '',
		interiorNumber: '',
		colony: '',
		years: 0,
		companyGroup: false,
		companyId: ''
	});

	const [legalRepFormData, setLegalRepFormData] = useState({
		legalRepName: '',
		legalRepLastName: '',
		legalRepSecondLastName: '',
		CURP: '',
		RFC: ''
	});

	const [contactDetailsFormData, setContactDetailsFormData] = useState({
		contactName: '',
		phone: '',
		firstLastName: '',
		secondLastName: '',
		email: '',
		username: '',
		brokers: [
			{
				idBroker: "",
				emailBroker: ""
			}
		]
	});

	const [fileFormData, setFileFormData] = useState({
		documentos: []
	});

	const [requestFormData, setRequestFormData] = useState(AperturaCuentaRequest);

	const [filesForm, setFilesForm] = useState([]);
	const [isCompanyData, setIsCompanyData] = useState(false);
	const [isLegalRepFormValid, setIsLegalRepFormValid] = useState(false);
	const [isContactDetailsFormValid, setIsContactDetailsFormValid] = useState(false);
	const [isFilesFormValid, setIsFilesFormValid] = useState(false);

	const handleCompanyData = (newFormData, formValid) => {
		setCompanyData(newFormData);
		setIsCompanyData(formValid);
	};

	const handleLegalRepFormDataChange = (newFormData, formValid) => {
		setLegalRepFormData(newFormData);
		setIsLegalRepFormValid(formValid);
	};

	const handleContactDetailsFormDataChange = (newFormData, formValid) => {
		setContactDetailsFormData(newFormData);
		setIsContactDetailsFormValid(formValid);
	};

	const handleFileFormDataChange = (newFormData, formValid) => {
		if (newFormData) setFileFormData(newFormData);
		setIsFilesFormValid(formValid);
	};

	const handleSubmit = () => {
		if (isCompanyData && step === 0) {
			return true;
		} else if (isLegalRepFormValid && step === 1) {
			return true;
		} else if (isContactDetailsFormValid && step === 2) {
			return true;
		} else if (isFilesFormValid && step === 3) {
			//console.log("FileFormData", fileFormData);
			return true;
		} else {
			console.log('Form is invalid');
			return false;
		}
	};

	const handlePreRegister = async (request) => {
		try {
			const response = await ApiPostApertureClient(request, request.companyGroup);
			console.log(response.data);
			setLoading(true);
			setOcurredError(false);
			if (!response.data.success) {
				setResponseNewPreRegister({
					type: "error",
					message: response.data.message,
					httpStatusCode: response.data.httpStatusCode,
					idPreRegister: "",
					idGroup: ""
				})
				setOcurredError(true);
			} else if (response.data.httpStatusCode === 100) {
				setResponseNewPreRegister({
					type: "info",
					message: "Su pre alta se ha enviado correctamente, estate pendiente de tu correo para más novedades.",
					httpStatusCode: response.data.httpStatusCode,
					idPreRegister: response.data.aperturaCuenta.idAperturaCuenta,
					idGroup: response.aperturaCuenta.data.claveGrupoEmpresarial
				})
				setOcurredError(false);
			} else {
				setResponseNewPreRegister({
					type: "success",
					message: "Su pre alta se ha enviado correctamente, estate pendiente de tu correo para más novedades.",
					httpStatusCode: response.data.httpStatusCode,
					idPreRegister: response.data.idAperturaCuenta,
					idGroup: response.data.claveGrupoEmpresarial,
				})
				setOcurredError(false);
			}
		}

		catch (error) {
			console.error("Error al hacer la solicitud:", error.response);

		}
		finally {
			setLoading(false);
		}
	}

	const handleNext = () => {
		if (isAllFormsComplete) {
			setStep(4);
		}
		if (step < steps.length - 1) {
			if (handleSubmit()) {
				if (step === steps.length - 2) {
					setIsAllFormsComplete(true)

					const fechaCreacionActual = new Date().toISOString();

					const finalFormData = { ...AperturaCuentaRequest };


					finalFormData.AperturaCuentaEmpresa = {
						idAperturaCuenta: "",
						nombreEmpresa: companyData.companyName,
						razonSocial: companyData.socialReason,
						rfcEmpresa: companyData.merchantRFC,
						descripcion: companyData.descripcion,
						calle: companyData.street,
						ciudad: companyData.city,
						codPostal: companyData.postalCode,
						colonia: companyData.colony,
						estado: companyData.state,
						numExterior: companyData.exteriorNumber,
						numInterior: companyData.interiorNumber,
						pais: companyData.country,
						tiempoResidenciaAnios: companyData.years,
						esGrupoEmpresarial: companyData.companyGroup,
						rutaDocumentos: "",
						idEstatus: 1,
						fechaApertura: fechaCreacionActual,
						claveGrupoEmpresarial: companyData.companyId,
						fechaModificacion: ""
					};


					finalFormData.AperturaCuentaRepresentante = {
						idAperturaCuentaRepresentante: 0,
						idAperturaCuenta: "",
						nombreRepresentante: legalRepFormData.legalRepName,
						primerAp: legalRepFormData.legalRepLastName,
						segundoAp: legalRepFormData.legalRepSecondLastName,
						curp: legalRepFormData.CURP,
						rfc: legalRepFormData.RFC
					};


					finalFormData.AperturaCuentaContacto = {
						idAperturaCuentaContacto: 0,
						idAperturaCuenta: "",
						nombre: contactDetailsFormData.contactName,
						primerAp: contactDetailsFormData.firstLastName,
						segundoAp: contactDetailsFormData.secondLastName,
						telefono: contactDetailsFormData.phone,
						correoElectronico: contactDetailsFormData.email,
						nombreUsuario: contactDetailsFormData.username
					};


					finalFormData.AperturaCuentaBrokers = {
						idAperturaCuentaBroker: 0,
						idAperturaCuenta: "",
						idBroker: contactDetailsFormData.brokers && contactDetailsFormData.brokers.length > 0 ? contactDetailsFormData.brokers[0].idBroker : ""
					};


					finalFormData.documentos = fileFormData.documentos.map((documento) => {
						return {
							idDocumento: documento.idDocumento,
							nombreDocumento: documento.nombreDocumento,
							docBase64: documento.docBase64,
							observaciones: "",
							fechaCreacion: fechaCreacionActual,
							idEstatus: 2,
						};
					});

					setRequestFormData(finalFormData);
					console.log("finalFormData", finalFormData);
					//handlePreRegister(finalFormData);
					//console.log(finalFormData);
				}

				setStep(step + 1);
			}
		}

	};

	useEffect(() => {
		if (step === 5) {
			//console.log(requestFormData);
			setCompanyData({
				...companyData,
				companyGroup: false,
			});
			setIsAllFormsComplete(false);
			handlePreRegister(requestFormData)

		}

	}, [step])

	const handlePrev = () => {
		if (step > 0) {
			setStep(step - 1);
		}
		if (step === 1) {
			setCompanyData({
				...companyData,
				companyGroup: false,
			});
		}
		if (step === 5) {
			setStep(0)
		}
	};

	const fetchDocuments = async () => {
		try {
			const documentos = await ServiceGetDocumentsApertureAccount();
			const nuevosArchivos = documentos.map((documento) => ({
				idDocumento: documento.idDocumento,
				nombre: documento.documento,
				tipo: "file",
				required: documento.requerido,
				description: documento.descripcion,
			}));

			setFilesForm(nuevosArchivos);
		} catch (error) {
			setFilesForm([
				{
					idDocumento: 1,
					nombre: "Error en la carga de los documentos",
					tipo: "file",
					required: true,
					description: "No se pudo cargar los documentos a enviar, por favor intente después...",
				},
			]);
			console.error("Error fetching documents:", error);
		}
	};

	const styleConfig = {
		completedBgColor: "#32AC61",
		activeBgColor: "#1C1E3C"
	};

	const connectorStyleConfig = {
		activeColor: "#32AC61",
		completedColor: "#32AC61"
	};

	useEffect(() => {
		fetchDocuments();
	}, []);


	return (
		<div className='container mt-5'>
			<div className="d-flex flex-column align-items-center justify-content-around">
				<div className="d-flex flex-column">
					<Stepper style={{ width: "80vw" }} connectorStateColors={true} activeStep={step} connectorStyleConfig={connectorStyleConfig} styleConfig={styleConfig}>
						<Step label="Empresa" />
						<Step label="Representante" />
						<Step label="Datos de contacto" />
						<Step label="Documentación" />
						<Step label="Finalizado" />
					</Stepper>
				</div>

				{step === 0 && <CompanyForm formData={companyData} onFormDataChange={handleCompanyData} />}
				{step === 1 && <LegalRepresentativeForm formData={legalRepFormData} onFormDataChange={handleLegalRepFormDataChange} />}
				{step === 2 && <ContactDetailsForm formData={contactDetailsFormData} onFormDataChange={handleContactDetailsFormDataChange} />}
				{step === 3 && <DocumentationFilesForm formData={fileFormData} onFormDataChange={handleFileFormDataChange} filesForm={filesForm} />}
				{step === 4 && (
					<ResumenFormulario
						companyData={companyData}
						legalRepFormData={legalRepFormData}
						contactDetailsFormData={contactDetailsFormData}
						fileFormData={fileFormData}
						onEditStep={setStep}
					/>
				)}

				{step === 5 && (
					loading ? (
						<Spinner variant="primary" animation="border" role="status"></Spinner>
					) : (
						ocurredError ? (
							<AlertaFormulario httpStatusCode={responseNewPreRegister.httpStatusCode} tipo="danger" titulo="Algo ocurrió..." mensaje={responseNewPreRegister.message} />
						) : (
							<AlertaFormulario httpStatusCode={responseNewPreRegister.httpStatusCode} tipo={responseNewPreRegister.type} titulo="Formulario enviado" mensaje={responseNewPreRegister.message} idAperturaCuenta={responseNewPreRegister.idPreRegister} idGrupoEmpresarial={responseNewPreRegister.idGroup} />
						)
					)
				)}

				<div className="d-flex justify-content-around mt-4">

					<Button className="mx-2" variant="secondary" onClick={handlePrev} disabled={step === 0}>
						{step === 5 ? "Regresar" : "Anterior"}
					</Button>
					{step < 4 && (
						<Button variant="primary" onClick={isAllFormsComplete ? () => { setStep(4); } : handleNext} disabled={step === steps.length - 1} className="mx-2">
							{isAllFormsComplete ? "Continuar" : "Siguiente"}
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};

export default FormularioPreRegistro;
