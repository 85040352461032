import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import "./Stepper.css"
const ResumenFormulario = ({ companyData, legalRepFormData, contactDetailsFormData, fileFormData, onEditStep }) => {
    console.log(companyData);
    
    return (
        <>

            <div className='d-flex flex-column align-items-center justify-content-around'>
                <h3 >Resumen de Información</h3>
                <Row className='my-5'>
                    <Col className='text'>
                        <h5>Empresa <Button onClick={() => onEditStep(0)} variant="link"><i class="bi bi-pencil"></i></Button></h5>
                        <p > <span>Nombre de la empresa:</span> {companyData.companyName}</p>
                        <p> <span>Razón social:</span> {companyData.socialReason}</p>
                        <p > <span>RFC:</span> {companyData.merchantRFC}</p>
                        <p > <span>Comentarios:</span> {companyData.descripcion? companyData.descripcion : "-"}</p>
                        <p> <span>País:</span> {companyData.country}</p>
                        <p> <span>Estado:</span> {companyData.state}</p>
                        <p > <span>Ciudad:</span> {companyData.city}</p>
                        <p > <span>Calle:</span> {companyData.street}</p>
                        <p > <span>Número exterior:</span> {companyData.exteriorNumber}</p>
                        <p > <span>Número interior:</span> {companyData.interiorNumber? companyData.interiorNumber : "-"}</p>
                        <p > <span>Código postal:</span> {companyData.postalCode}</p>
                        <p > <span>Colonia:</span> {companyData.colony}</p>
                        <p> <span>Años de residencia:</span> {companyData.years}</p>
                        <p> <span>Grupo empresarial:</span> {!companyData.companyGroup? companyData.companyId :"Usted eligió crear un grupo"}</p>
                        

                       
                    </Col>
                    <Col className='text'>
                        <h5>Representante Legal <Button onClick={() => onEditStep(1)} variant="link"><i class="bi bi-pencil"></i></Button></h5>
                        <p > <span>Nombre:</span> {legalRepFormData.legalRepName}</p>
                        <p > <span>Apellido Paterno:</span> {legalRepFormData.legalRepLastName}</p>
                        <p > <span>Apellido Materno:</span> {legalRepFormData.legalRepSecondLastName}</p>
                        <p > <span>CURP:</span> {legalRepFormData.CURP}</p>
                        <p > <span>RFC:</span> {legalRepFormData.RFC}</p>
                    </Col>
                    <Col className='text'>
                        <h5>Datos de Contacto <Button onClick={() => onEditStep(2)} variant="link"><i class="bi bi-pencil"></i></Button></h5>
                        <p > <span>Nombre del contacto:</span> {contactDetailsFormData.contactName}</p>
                        <p > <span>Apellido Paterno:</span> {contactDetailsFormData.firstLastName}</p>
                        <p > <span>Apellido Materno:</span> {contactDetailsFormData.secondLastName}</p>
                        <p > <span>Correo electrónico:</span> {contactDetailsFormData.email}</p>
                        <p > <span>Usuario:</span> {contactDetailsFormData.username}</p>
                    </Col>
                    <Col>
                        <h5>Documentación <Button onClick={() => onEditStep(3)} variant="link"><i class="bi bi-pencil"></i></Button></h5>
                        {fileFormData.documentos.map((doc, index) => (
                            <p key={index} >{doc.nombreDocumento}</p>
                        ))}
                    </Col>
                </Row>


                <Button variant="primary" onClick={() => onEditStep(5)}>
                    Confirmar y Enviar
                </Button>
            </div>

        </>

    );
};

export default ResumenFormulario;
